type Resource = {
  name: string;
  description?: string;
  link: string;
};

const resources: Resource[] = [
  {
    name: 'Mote Marine Laboratory & Aquarium',
    link: 'https://mote.org/',
    description: "General information about Mote Marine Laboratory & Aquarium (including ticket information).",
  },
  {
    name: 'Florida Red Tide FAQs – Mote Marine Laboratory & Aquarium',
    link: 'https://mote.org/news/florida-red-tide',
    description: "Frequently asked questions regarding Florida red tide.",
  },
  {
    name: 'FWC Red Tide Current Status Map',
    link:
      'http://myfwc.maps.arcgis.com/apps/View/index.html?appid=87162eec3eb846218cec711d16462a72',
      description: "Updates on the status of Florida red tide from the Florida Fish and Wildlife Conservation Commission.",
  },
  {
    name: 'FWC/FWRI Fish Kill Hotline',
    link: 'https://public.myfwc.com/FWRI/FishKillReport/Submit.aspx',
    description: "Report fish kills to the Florida Wildlife Research Institute (FWRI). ",
  },
  {
    name: 'NOAA/NCCOS Red Tide Forecast',
    link: 'https://coastalscience.noaa.gov/science-areas/habs/hab-forecasts/gulf-of-mexico/',
    description: "Harmful algal bloom (HAB) forecasts from the National Centers for Coastal Ocean Science (NCCOS).",
  },
  {
    name: "FDOH Florida Healthy Beaches Program",
    link:
      'http://www.floridahealth.gov/environmental-health/beach-water-quality/index.html',
      description: "Current enterococcus bacteria levels and no-swim advisories from the Florida Healthy Beaches Program.",
  },
  {
    name: 'SECOORA',
    description: 'Observing system information from the Southeast Coastal Ocean Observing Regional Association (SECOORA) including buoys, water level and water quality stations, high-frequency radars, a glider observatory, models, and other products for North Carolina, South Carolina, Georgia, and Florida.',
    link: 'https://secoora.org/',
  },
  {
    name: "How's the Beach?",
    link: 'http://howsthebeach.org/',
    description: "Daily predictions of bacteria conditions from How’s the Beach for locations along the southeast coast of the US including: Kill Devil Hills region of NC; Long Bay, Charleston Harbor, and Folly Beach regions of SC; and Sarasota and Manatee County regions of FL.",
  },
  {
    name: 'SEAFAN Dashboard',
    link: 'https://www.arcgis.com/apps/dashboards/9178cc052a404592a921431771a020e8',
    description: "View Southeast Florida Action Network (SEAFAN) citizen reports online including marine debris, vessel groundings and anchor damage, invasive species, harmful algal blooms, fish disease and fish kills, discolored water, and coral disease and bleaching.",
  },
  {
    name: 'Submit a SEAFAN Report',
    link: 'https://survey123.arcgis.com/share/68329a3ac2ac49388409f1447a642c45?portalUrl=https://www.arcgis.com',
    description: "The Southeast Florida Action Network (SEAFAN) is a citizen reporting and response system designed to improve the protection and management of southeast Florida's offshore coral reefs. Report any unusual sightings here when scuba diving or snorkeling offshore in Martin, Palm Beach, Broward, Miami-Dade and Monroe counties.",
  },
  {
    name: 'National Weather Service',
    link: 'https://www.weather.gov/',
    description: "Weather information including forecasts and advisories from the National Weather Service (NWS).",
  },
  {
    name: 'Mote Sea Turtle Nesting Program',
    link: 'https://mote.org/news/environmental-updates/2022-sea-turtle-nesting',
    description: "General information about Mote’s Sea Turtle Nesting Program, ways you can help protect sea turtles, and view weekly sea turtle nesting data.",
  },
  {
  	name: "Report Sick, Injured, or Stranded Sea Turtles",
  	link: " https://mote.org/research/program/sea-turtle-conservation-research",
  	description: "Inside Sarasota and Manatee County waters, please call Mote Marine Laboratory’s Stranding Investigations Program at 888-345-2335. Outside of Sarasota and Manatee County waters, please call the Florida Fish and Wildlife Conservation Commission (FWC) at 888-404-FWCC (3922).",
  },
  {
    name: "FWC's Florida Shorebird Alliance",
    link: 'https://flshorebirdalliance.org/resources/',
    description: "News and resources regarding shorebirds, as well as ways to get involved with shorebird research and conservation with the Florida Shorebird Alliance (FSA).",
  },
  {
    name: 'Protecting Florida Together',
    link: 'https://protectingfloridatogether.gov/',
    description: "Learn how The Florida Department of Environmental Protection (FDEP), Florida Department of Health (FDOH), and Florida Fish and Wildlife Conservation Commission (FWC) are working together to protect and restore our environment through Protecting Florida Together.",
  },
  {
    name: 'Be a Beach Hero!',
    description: 'Learn how you can be a Beach Hero.',
    link:
      'https://fl.audubon.org/sites/default/files/static_pages/attachments/beabeachhero_final.pdf',
  },
  {
    name: 'Florida DEP Hurricane and Tropical Storm Reports',
    description: '',
    link:
      'https://floridadep.gov/rcp/coastal-engineering-geology/content/coastal-engineering-geology-group-technical-reports#storms',
  },
  {
    name: 'Shark Bycatch Data',
    description: 'Shark bycatch information from commercial fishing activities in the Gulf of Mexico as recorded by the Center for Fisheries Electronic Monitoring at Mote.',
    link:
      'https://cfemm.shinyapps.io/SharkBycatch_App',
  },
  {
    name: 'FWC Shark Safety Information',
    description: 'How to reduce the odds of a shark bite.',
    link:
      'https://myfwc.com/research/saltwater/sharks-rays/interaction/reducing-odds/',
  },
];

export default resources;
